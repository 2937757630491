.name{
    background-color: green;
    color: black;
    display: flex;
  }
  .size{
    width: 100px;
    height: 100px;
  }
  .position{
    margin-left: 300px;
  }
  .position1{
    background-color: black;
    color: white;
    display: flex;
  }
  .move6{
    margin-left: 100px;
  }
  .move7{
    margin-left: 100px;
    background-color: black;
    color: white;
  }
  .move8{
    margin-left: 100px;
  }
  .move9{
    margin-left: 100px;
  }
  .move10{
    margin-left: 100px;
  }
  .move11{
    margin-left: 100px;
  }
  .move12{
    margin-left: 100px;
  }
  .photosize{
    display: flex;
    width: 100%;
  }
  .move6:hover{
    color: yellow;
  }
  .move7:hover{
    color: yellow;
  }
  .move8:hover{
    color: yellow;
  }
  .move9:hover{
    color: yellow;
  }
  .move10:hover{
    color: yellow;
  }
  .move11:hover{
    color: yellow;
  }
  .move12:hover{
    color: yellow;
  }