.styling{
    background-color: white;
    color: green;
    font-family: cursive;
    font-size: 30px;
}
.a1{
    display: flex;
}
.image1{
    width: 450px;
    height: 250px;
}
.image2{
    width: 450px;
    height: 250px;
}
.image3{
    width: 600px;
    height: 505px;
    align-content: center;
}
.image4{
    width: 465px;
    height: 250px;
}
.image5{
    width: 465px;
    height: 250px;
}
.styling1{
    background-color: white;
    color: green;
    font-family: cursive;
    font-size: 40px;
    text-align: center;
}
.text{
    font-size: 20px;
}
.text1{
    font-size: 20px;
    text-align: left;
    margin-left: 60px;
}
.image6{
    width: 800px;
    height: 300px;
}
.text2{
    margin-left: 100px;
    font-size: 40px;
    font-family: 'Segoe UI';
}
.styling2{
    background-color: white;
    color: green;
    font-family: cursive;
    font-size: 40px;
    text-align: left;
    margin-left: 40px;
}
.place{
    display: flex;
}
.text3{
    background-color: yellow;
    color: black;
    width: 500px;
    border-radius: 100px;
    justify-content: center;
    margin-right: 100px;
    margin-left: 50px;
}
.align{
    display: flex;
}
.styling3{
    display: flex;
}
.l1{
    font-size: 25px;
    margin-left: 20px;
    text-align: start;
}
.l2{
    display: flex;
    margin-left: 40px;
}
.l3{
    width: 300px;
    height: 200px;
    margin-left: 100px;
    justify-content: center;
}