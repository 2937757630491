.contact{
    background-color: black;
    color: white;
    display: flex;
  }
  .move1{
    margin-left: 60px;
  }
  .move2{
    margin-left: 80px;
  }
  .move3{
    margin-left: 80px;
  }
  .move4{
    margin-left: 500px;
  }
  .move5{
    margin-left: 100px;
  }
 .move1:hover{
  color: yellow;
 }
 .move2:hover{
  color:yellow;
 }
 .move3:hover{
  color: yellow;
 }
 .move4:hover{
  color: yellow;
 }
 .move5:hover{
  color: yellow;
 }
 